import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import { LoadingSpinner, LoadingSpinnerFullScreenWrapper, ProtectedRoute, SEO } from 'components';
import { defaultColors } from 'styles';

const Admin = () => {
  const { loading, firebase, user } = useContext(FirebaseContext);
  const [uidOfUserToGrantAdminPermissionsTo, setUidOfUserToGrantAdminPermissionsTo] = useState('');
  const [grantingUserAdminPermissions, setGrantingUserAdminPermissions] = useState('');
  const [uidOfUserToGrantModeratorPermissionsTo, setUidOfUserToGrantModeratorPermissionsTo] =
    useState('');
  const [grantingUserModeratorPermissions, setGrantingUserModeratorPermissions] = useState('');
  // const [ sendingReminderEmail, setSendingReminderEmail ] = useState();

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  const handleGrantUserAdminPermissions = (e) => {
    e.persist();
    setUidOfUserToGrantAdminPermissionsTo(e.target.value);
  };

  const handleGrantUserModeratorPermissions = (e) => {
    e.persist();
    setUidOfUserToGrantModeratorPermissionsTo(e.target.value);
  };

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Admin" />
      <Wrapper>
        {!user ? (
          <LoadingSpinnerFullScreenWrapper>
            <LoadingSpinner
              size="2rem"
              style={{ width: '7.5rem', color: defaultColors.secondary }}
            />
          </LoadingSpinnerFullScreenWrapper>
        ) : (
          <>
            <Button
              onClick={async () => {
                const uidArray = [];

                await firebase.fetchAllUsers().then(({ data: allUsers }) => {
                  allUsers.forEach((_user) => {
                    // Go here to convert the event date and time to a timestamp - www.timestampconvert.com
                    // and then add three zeros onto the end to make it compatible with the Firebase timestamp. //
                    if (new Date(_user.metadata.lastRefreshTime).getTime() >= 1621530000000) {
                      uidArray.push(_user.uid);
                    }
                  });
                });

                const userNamesAndEmailsArray = [];

                const run = async () => {
                  const promises = [];

                  uidArray.forEach((uid) => {
                    promises.push(firebase.getUserFromDatabaseWithUID({ uid }));
                  });

                  const promiseResults = await Promise.all(promises);

                  promiseResults.forEach((res) => {
                    const { name, email, workplaceName, eventsUserCanAccess } = res.docs[0].data();
                    if (
                      eventsUserCanAccess.includes('Paediatric Horizons') &&
                      !email.includes('agencyx.ie')
                    ) {
                      userNamesAndEmailsArray.push({
                        name,
                        email,
                        workplaceName
                      });
                    }
                  });
                };

                await run();

                const sortedUsers = userNamesAndEmailsArray.sort((a, b) =>
                  a.name.toLowerCase() !== b.name.toLowerCase()
                    ? a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : 1
                    : 0
                );

                const sortedUsersWithRowNumbers = sortedUsers.map((entry, i) => ({
                  rowNumber: i + 1,
                  ...entry
                }));

                firebase
                  .generateLoggedInOnTheDayReport({ users: sortedUsersWithRowNumbers })
                  .then(
                    ({ data: _data }) =>
                      window.open(_data, '_blank') || window.location.replace(_data)
                  )
                  .catch(console.error);
              }}>
              Generate Logged In On The Day Report
            </Button>
            <br />
            <br />
            <Button
              type="button"
              onClick={() => {
                firebase.fetchAllUsers().then(({ data: allUsers }) => {
                  allUsers.forEach((_user) => {
                    firebase.getUserFromDatabaseWithUID({ uid: _user.uid }).then((res) => {
                      if (res.empty) {
                        console.log(_user.uid);
                      } else {
                        console.log('NOT EMPTY');
                      }
                    });
                  });
                });
              }}>
              Get Invalid UIDs
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.55rem'
              }}>
              <input
                style={{
                  outlineColor: defaultColors.secondary,
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToGrantAdminPermissionsTo}
                placeholder="UID"
                onChange={handleGrantUserAdminPermissions}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setGrantingUserAdminPermissions(true);
                    await firebase.grantUserAdminPermissions({
                      uid: uidOfUserToGrantAdminPermissionsTo
                    });
                    setUidOfUserToGrantAdminPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setGrantingUserAdminPermissions(false);
                  }
                }}>
                {grantingUserAdminPermissions ? (
                  <LoadingSpinner size="2rem" />
                ) : (
                  `Make User An Admin`
                )}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '2.55rem'
              }}>
              <input
                style={{
                  outlineColor: defaultColors.secondary,
                  width: '300px',
                  height: '50px',
                  fontSize: '1.25rem',
                  padding: '0.6em',
                  marginBottom: '12px'
                }}
                type="text"
                name="uid"
                value={uidOfUserToGrantModeratorPermissionsTo}
                placeholder="UID"
                onChange={handleGrantUserModeratorPermissions}
              />
              <Button
                style={{
                  width: '200px'
                }}
                onClick={async () => {
                  try {
                    setGrantingUserModeratorPermissions(true);
                    await firebase.grantUserModeratorPermissions({
                      uid: uidOfUserToGrantModeratorPermissionsTo
                    });
                    setUidOfUserToGrantModeratorPermissionsTo('');
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setGrantingUserModeratorPermissions(false);
                  }
                }}>
                {grantingUserModeratorPermissions ? (
                  <LoadingSpinner size="2rem" />
                ) : (
                  `Make User A Moderator`
                )}
              </Button>
              {/* <Button
                style={{
                  width: '180px'
                }}
                onClick={async () => {
                  try {
                    setSendingReminderEmail(true);
                    await firebase.sendReminderEmail();
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setSendingReminderEmail(false);
                  }
                }}>
                {sendingReminderEmail ? <LoadingSpinner /> : `Send Reminder Email`}
              </Button> */}
            </div>
          </>
        )}
      </Wrapper>
    </ProtectedRoute>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  margin: 2.55rem 1rem 0;
  padding-top: 10rem;
  width: 100%;
`;

const ButtonStyles = css`
  align-items: center;
  background: ${defaultColors.secondary};
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 700;
  height: 3.5em;
  justify-content: center;
  margin: 0 6px 12px;
  padding: 1em 0;
  text-align: center;
  text-transform: uppercase;
  width: 200px;

  &:hover {
    filter: brightness(112%);
    transition: filter 150ms;
  }
`;

const Button = styled(motion.button).attrs({
  whileHover: {
    scale: 1.025
  },
  whileTap: {
    scale: 0.95
  }
})`
  ${ButtonStyles}
`;

export default Admin;
